import { gettext } from "django-i18n";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

export interface FileInputFieldProps {
  accept?: string;
  disabled?: boolean;
  errorText?: string;
  helperText?: string;
  id?: string;
  value?: string;
  placeholder?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeClear?: () => void;
}

/**
 * Input component which let's you pick a file.
 */
const FileInputField: React.FC<FileInputFieldProps> = ({
  accept,
  disabled,
  errorText,
  id,
  placeholder,
  helperText,
  name,
  onChange,
  onChangeClear,
  value,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const selectFile = () => inputRef.current?.click();
  const clearFile = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      onChangeClear && onChangeClear();
    }
  };

  // ensure that the hidden input field value is cleared when the form
  // value is cleared (since onChange is only fired when file upload occurs,
  // this won't be cleared automatically)
  if (!value && inputRef.current) {
    inputRef.current.value = "";
  }

  return (
    <>
      <TextField
        fullWidth
        disabled={disabled}
        helperText={errorText ?? helperText}
        error={!!errorText}
        placeholder={placeholder}
        value={value}
        sx={{
          "& .MuiFormHelperText-root": {
            marginBottom: "0px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                cursor: disabled ? "default" : "pointer",
              }}
              position="start"
              onClick={selectFile}
            />
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                sx={{
                  cursor: disabled ? "default" : "pointer",
                }}
                disabled={disabled}
                onClick={value ? clearFile : selectFile}
              >
                {value ? (
                  <ClearIcon data-cy="file-input-clear-icon" />
                ) : (
                  <AttachFileIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <input
        style={{ display: "none" }}
        id={id}
        name={name}
        accept={accept}
        data-cy="file-input-field"
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange && e.target.files?.[0] && onChange(e)
        }
        ref={inputRef}
        type="file"
      />
    </>
  );
};

FileInputField.defaultProps = {
  placeholder: gettext("Browse ...") as string,
  value: "",
};

export default FileInputField;
