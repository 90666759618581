import { gettext } from "django-i18n";

import { makeToast } from "_common/components/toast/actions";

export function processApiErrors({ dispatch, error, form, onError }) {
  const { response } = error;

  const badStatusCodes = [404, 405, 500, 504];

  if (!response || !response.status) {
    dispatch(
      makeToast(
        "error",
        gettext("A network error occurred while processing the request.")
      )
    );
  } else if (badStatusCodes.includes(response.status)) {
    dispatch(
      makeToast(
        "error",
        `${response.status}: ${gettext(
          "An error occurred while processing the request."
        )}`
      )
    );
  } else if (response.status === 403) {
    dispatch(
      makeToast("error", gettext("You are not authorized for this request."))
    );
  } else if (
    !form &&
    response.data &&
    response.data.errors &&
    response.data.errors.length
  ) {
    dispatch(makeToast("error", response.data.errors[0]));
  }

  if (onError) {
    return onError(response);
  }
}

/**
 * Performs a given api request and handles redux state changes
 * @param {object} dispatch - The React dispatch hook.
 * @param {ApiActionCreator} action - The action being performed.
 * @param {function} fn - The api request to make
 * @param {any} [form=null] - The form this request is made from if applicable.
 * @param {function} [resultMap=null] - Function to be performed on the results of the request.
 * @param {any} [data=null] - Payload in the initial request action.
 * @param {function} [onError=null] - Function to be performed if there was an error.
 * @param {boolean} ignoreOldRequests - Flags to determine if action timestamp needs to be checked.
 * @param {boolean} [displayErrors=true] - Flag to determine whether to use `processApiErrors` to create toast messages
 */
export async function apiRequest({
  dispatch,
  action,
  fn,
  form = null,
  resultMap = null,
  data = null,
  onError = null,
  ignoreOldRequests = false,
  // Used to suppress generic toast messages
  // NOTE: Not recommended for general use
  displayErrors = true,
}) {
  dispatch(action.request(data));

  let response;
  let responseData;
  let error;

  const timestamp = new Date().getTime();
  action.timestamp = timestamp;

  try {
    response = await fn();
  } catch (err) {
    error = err;
    // if (!displayErrors) {
    //   throw error;
    // }
  }

  if (ignoreOldRequests && action.timestamp !== timestamp) {
    return;
  }

  if (error) {
    if (!window.test) {
      console.error(error); // eslint-disable-line no-console
    }
    dispatch(action.error(error.response));
    if (displayErrors) {
      processApiErrors({ dispatch, error, form, onError });
    }
  } else {
    if (resultMap) {
      responseData = resultMap(response.data);
    } else if (response.data && response.data.hasOwnProperty("results")) {
      responseData = response.data.results;
    } else {
      responseData = response.data;
    }

    dispatch(action.success(responseData));

    // action is done, clear its intent
    dispatch(action.intent(false));
  }

  dispatch(action.complete(response));

  return responseData;
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function recursiveRefresh({
  action,
  getState,
  dispatch,
  selector,
}) {
  let latestState = getState();
  let needsRefresh = selector(latestState);
  if (needsRefresh) {
    const interval = 5 * 1000;
    await delay(interval);
    latestState = getState();
    needsRefresh = selector(latestState);
    if (needsRefresh) {
      dispatch(action);
    }
  }
}
