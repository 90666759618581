import React from "react";
import { getIn } from "formik";
import clsx from "clsx";

import Checkbox from "@deprecated/material-ui/Checkbox";
import RadioButton, {
  RadioButtonGroup,
} from "@deprecated/material-ui/RadioButton";
import Toggle from "@deprecated/material-ui/Toggle";

import AutoComplete from "../auto-complete";
import CheckboxGroup from "../checkbox-group";
import DatePicker from "../DatePicker";
import DateRangePicker from "../date-range-picker";
import DynamicObject from "../dynamic-object";
import FileInput from "../FileInputField";
import FileUploadTextField from "../file-upload-text-field";
import GlobalRoles from "../roles/GlobalRoles";
import SelectField from "../SelectField";
import ConnectedTags from "../tags";
import TextField from "../TextField";
import PasswordField from "../PasswordField";
import MuiltvalueStringField from "../MultivalueStringField";
import FileTextComboField from "../FileTextComboField";

import { mergeProps } from "./utils";

// Material UI components

/*
 * Renders a checkbox (boolean) field.
 */
export const renderCheckbox = (props) => {
  const { onCheck, form, field } = props;

  // A disabled input will cause mouseLeave to not fire
  // for things like tooltip. We use pointerEvents to overcome this.
  // See react issue #4251

  return (
    <Checkbox
      {...mergeProps(props)}
      inputStyle={{
        pointerEvents: props.disabled ? "none" : "all",
      }}
      checked={!!field.value}
      onCheck={(e, value) => {
        if (onCheck) {
          onCheck(e, value);
        } else {
          form.setFieldValue(field.name, value);
        }
      }}
    />
  );
};

export const renderRadioButton = ({ horizontal, ...props }) => {
  const horizontalStyle = {
    ...props.style,
    display: "inline-block",
    width: "auto",
    whiteSpace: "nowrap",
    paddingRight: "24px",
  };

  const newProps = {
    ...props,
    style: horizontal ? horizontalStyle : props.style,
  };

  const classNames = clsx({
    "sde-survey-question-answer": true,
    "sde-survey-question-answer-dirty": props.dirty,
  });

  // Type checking for string because project survey page passes in components (Tooltipify) rather than flat strings.
  return typeof newProps.label === "string" ? (
    <RadioButton
      data-cy={`radio-button-${newProps.id || newProps.label}`}
      className={classNames}
      {...newProps}
      label={newProps.label}
    />
  ) : (
    <RadioButton
      data-cy={`radio-button-${newProps.value || newProps.label}`}
      className={classNames}
      {...newProps}
    />
  );
};

export const renderRadioButtonGroup = (props) => {
  const { field } = props;

  return (
    <RadioButtonGroup
      {...mergeProps(props)}
      onChange={field.onChange}
      valueSelected={props.valueSelected || field.value}
    />
  );
};

/**
 * Renders a toggle (boolean) field.
 */
export const renderToggle = (props) => {
  const { field, form } = props;

  return (
    <Toggle
      {...mergeProps(props)}
      onToggle={(e, value) => {
        form.setFieldValue(field.name, value);
      }}
      toggled={!!field.value}
    />
  );
};

export const renderPasswordField = ({ validFieldText, ...props }) => {
  const { form, field } = props;
  const touched = getIn(form.touched, field.name);
  const newProps = mergeProps(props);

  if (touched && !newProps.errorText) {
    newProps.errorStyle = { color: "#4caf50" };
    newProps.errorText = validFieldText;
  }

  return <PasswordField {...newProps} disableButton={!touched} />;
};

// SDE components
export const renderAutoComplete = (props) => (
  <AutoComplete {...mergeProps(props)} />
);

export const renderCheckboxGroup = (props) => (
  <CheckboxGroup {...mergeProps(props)} />
);

export const renderDatePicker = (props) => (
  <DatePicker {...mergeProps(props)} />
);

export const renderDateRangePicker = (props) => (
  <DateRangePicker {...mergeProps(props)} />
);

export const renderDynamicObjectField = (props) => (
  <DynamicObject {...mergeProps(props)} />
);

export const renderFileTextComboInput = (props) => {
  const { form, field } = props;
  const newProps = mergeProps(props);

  newProps.onChangeValue = (text) => {
    form.setFieldValue(field.name, text);
  };

  newProps.onChangeClear = () => form.setFieldValue(field.name, "");

  return (
    <FileTextComboField
      {...newProps}
      errorText={form.dirty ? getIn(form.errors, field.name) : undefined}
    />
  );
};

export const renderFileInput = (props) => {
  const { form, field } = props;
  const newProps = mergeProps(props);

  newProps.onChange = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      form.setFieldValue(field.name, file);
    };
    reader.readAsDataURL(file);
  };

  newProps.onChangeClear = () => form.setFieldValue(field.name, "");
  newProps.value = newProps.value && newProps.value.name;

  return (
    <FileInput
      {...newProps}
      errorText={form.dirty ? getIn(form.errors, field.name) : undefined}
    />
  );
};

export const renderFileUploadTextField = (props) => {
  const { form, field } = props;

  return (
    <FileUploadTextField
      {...mergeProps(props)}
      errorText={props.errorText || getIn(form.errors, field.name)}
    />
  );
};

export const renderGlobalRoles = (props) => {
  const { field } = props;

  return (
    <GlobalRoles
      {...mergeProps(props)}
      onChange={(event, index, value) => {
        event.target = {
          name: field.name,
          value,
        };
        field.onChange(event, value);
      }}
    />
  );
};

export const renderSelectField = (props) => {
  const { field } = props;

  return (
    <SelectField
      {...mergeProps(props)}
      onChange={(event, index, value) => {
        event.target = {
          name: field.name,
          value,
        };
        field.onChange(event, value);
      }}
    />
  );
};
export const renderTags = (props) => <ConnectedTags {...mergeProps(props)} />;
export const renderTextField = (props) => <TextField {...mergeProps(props)} />;
export const renderMultiLineTextField = (props) => (
  <TextField multiLine rowsMax={5} {...mergeProps(props)} />
);

export const renderMultivalueStringField = (props) => {
  const { onChange, form, field } = props;

  return (
    <MuiltvalueStringField
      {...mergeProps(props)}
      onChange={(event, newValue, reason) => {
        if (onChange) {
          onChange(event, newValue, reason);
        } else {
          form.setFieldValue(field.name, newValue);
        }
      }}
    />
  );
};
