import React from "react";

import MaterialPasswordField from "@deprecated/material-ui/PasswordField";
import { PasswordFieldProps } from "@deprecated/material-ui";

import { getTextFieldStyleProps } from "../TextField/utils";

const mergePropsWithDefaults = (props: PasswordFieldProps) => ({
  ...props,
  ...getTextFieldStyleProps(props),
  visibilityIconStyle: {
    opacity: props.disabled ? 0.38 : 0.87,
    ...props.visibilityIconStyle,
  },
});

/**
 * Input component for passwords that wraps a text input
 */
const PasswordField = ({ style, ...props }: PasswordFieldProps) => (
  <MaterialPasswordField
    {...mergePropsWithDefaults(props)}
    style={{
      // The line might not occupy the full width if
      // this is not set!
      boxSizing: "initial",
      ...style,
    }}
  />
);

PasswordField.defaultProps = {
  autoComplete: "off",
  fullWidth: true,
};

export default PasswordField;
