import React from "react";
import { Link } from "react-router-dom";

import {
  Breadcrumbs as DynamicBreadcrumbs,
  BreadcrumbsItem,
} from "react-breadcrumbs-dynamic";

const Breadcrumbs = (props) => {
  const separator = (
    <span
      className="sde-breadcrumbs-item-separator"
      style={{ verticalAlign: "bottom" }}
    >
      {">"}
    </span>
  );

  const renderItem = ({ children, ...props }) => {
    const link = (props.href && <a {...props}>{children}</a>) || (
      <Link {...props}>{children}</Link>
    );

    return <span className="sde-breadcrumbs-item">{link}</span>;
  };

  return (
    <nav className="sde-breadcrumbs sde-container" {...props}>
      <DynamicBreadcrumbs
        separator={separator}
        item={renderItem}
        finalItem="span"
        className="sde-breadcrumbs-item"
      />
    </nav>
  );
};

const Breadcrumb = ({ to, href, children, ...otherProps }) => (
  <BreadcrumbsItem
    className="sde-breadcrumbs-item"
    key={to || href || window.location.pathname}
    to={to || href || window.location.pathname}
    href={href}
    {...otherProps}
  >
    {children}
  </BreadcrumbsItem>
);

export { Breadcrumb };

export default Breadcrumbs;
