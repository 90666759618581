import { gettext } from "django-i18n";
import { memoize } from "lodash";
import { createValidator } from "./utils";

export const required = createValidator((value) => {
  if (value === undefined || value === null || value === "") {
    return false;
  }

  if (value instanceof Array) {
    return value.length > 0;
  }

  const isString = typeof value === "string";

  return !isString || !!value.trim();
}, gettext("This field is required"));

export const notEmpty = createValidator(
  (value) => !value || !!value.trim(),
  gettext("This field cannot be empty")
);

export const minLength = memoize((length) =>
  createValidator(
    (value) => !value || !value.length >= length,
    gettext("This field must be %s characters or longer").format(length)
  )
);

export const maxLength = memoize((length) =>
  createValidator(
    (value) => !value || value.length <= length,
    gettext("This field may not be greater than %s characters").format(length)
  )
);

export const integer = createValidator(
  (value) => (!value && value !== 0) || /^[+-]?\d+$/.test(value),
  gettext("This field must be an integer")
);

export const minValue = memoize((min) =>
  createValidator(
    (value) => (!value && value !== 0) || value >= min,
    gettext("This field must be equal or greater than %s").format(min)
  )
);

export const maxValue = memoize((max) =>
  createValidator(
    (value) => (!value && value !== 0) || value <= max,
    gettext("This field must be equal or less than %s").format(max)
  )
);

export const email = createValidator(
  (value) =>
    !value ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ),
  gettext("This field must be a valid email")
);

/*
  Password validation regex breakdown:
  ^                  // the start of the string
  (?=.*[a-z])        // use positive look ahead to see if at least one lower case letter exists
  (?=.*[A-Z])        // use positive look ahead to see if at least one upper case letter exists
  (?=.*\d)           // use positive look ahead to see if at least one digit exists
  .+                 // gobble up the entire string
  $                  // the end of the string
*/
export const validPassword = createValidator(
  (value) => !value || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value),
  gettext(
    "A valid password must contain at least one uppercase, one lowercase and one digit"
  )
);

export const validateFileExtension = memoize((supportedFileExtensions) =>
  createValidator(
    (value) =>
      !value ||
      !supportedFileExtensions ||
      new RegExp(`\\.(${supportedFileExtensions.join("|")})$`, "i").test(
        value.name
      ),
    gettext("Invalid file extension. Expected one of %s.").format(
      supportedFileExtensions.join(", ")
    )
  )
);

export const validUrl = createValidator((value: string) => {
  if (value) {
    try {
      const url = new URL(value);
      return url !== null;
    } catch (err) {
      return false;
    }
  }
  return true;
}, gettext("Please enter a URL (ex: http://example.com)"));
