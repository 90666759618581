import { createTheme, Theme } from "@mui/material/styles";
import { gettext } from "django-i18n";

declare module "@mui/material/styles/createPalette" {
  interface SdePaletteColor {
    main: string;
    hover?: string;
    alpha87?: string;
    alpha60?: string;
    alpha50?: string;
    alpha38?: string;
    alpha32?: string;
    alpha28?: string;
    alpha20?: string;
    alpha14?: string;
    alpha12?: string;
    alpha8?: string;
    alpha4?: string;
    alpha1?: string;
  }

  interface Palette {
    grey1: Palette["primary"];
    grey4: Palette["primary"];
    grey8: Palette["primary"];
    grey12: Palette["primary"];
    grey28: Palette["primary"];
    grey38: Palette["primary"];
    grey60: Palette["primary"];
    grey87: Palette["primary"];
    greyBlue: Palette["primary"];
    white: Palette["primary"];
    whiteish: Palette["primary"];
    warningBlue: Palette["primary"];
    blue8: Palette["primary"];
    blueSolid4: Palette["primary"];

    blue: SdePaletteColor;
    red: SdePaletteColor;
    amber: SdePaletteColor;
    purple: SdePaletteColor;
  }

  interface PaletteOptions {
    grey1: PaletteOptions["primary"];
    grey4: PaletteOptions["primary"];
    grey8: PaletteOptions["primary"];
    grey12: PaletteOptions["primary"];
    grey28: PaletteOptions["primary"];
    grey38: PaletteOptions["primary"];
    grey60: PaletteOptions["primary"];
    grey87: PaletteOptions["primary"];
    greyBlue: PaletteOptions["primary"];
    white: PaletteOptions["primary"];
    whiteish: PaletteOptions["primary"];
    warningBlue: PaletteOptions["primary"];
    blue8: PaletteOptions["primary"];
    blueSolid4: PaletteOptions["primary"];

    blue: SdePaletteColor;
    red: SdePaletteColor;
    amber: SdePaletteColor;
    purple: SdePaletteColor;
  }
}

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// https://zeroheight.com/912a81b51/p/5317c4-typography
declare module "@mui/material/styles" {
  // allow configuration using `createTheme`
  interface TypographyVariants {
    body_medium: React.CSSProperties;
    body_regular: React.CSSProperties;
    title1_regular: React.CSSProperties;
    title1_medium: React.CSSProperties;
    title3_medium: React.CSSProperties;
    label_regular: React.CSSProperties;
    label_medium: React.CSSProperties;
    subtitle1_medium: React.CSSProperties;
    subtitle1_regular: React.CSSProperties;
    subtitle3_regular: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body_medium: React.CSSProperties;
    body_regular: React.CSSProperties;
    title1_regular: React.CSSProperties;
    title1_medium: React.CSSProperties;
    title3_medium: React.CSSProperties;
    label_regular: React.CSSProperties;
    label_medium: React.CSSProperties;
    subtitle1_medium: React.CSSProperties;
    subtitle1_regular: React.CSSProperties;
    subtitle3_regular: React.CSSProperties;
    button_small: React.CSSProperties;
    references_regular: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body_medium: true;
    body_regular: true;
    title1_regular: true;
    title1_medium: true;
    title3_medium: true;
    label_regular: true;
    label_medium: true;
    subtitle1_medium: true;
    subtitle1_regular: true;
    subtitle3_regular: true;
    button_small: true;
    references_regular: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    grey60: true;
    grey87: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    grey60: true;
    grey87: true;
  }
}

export const colors = {
  amber: "rgb(255, 193, 7)",
  amberHover: "rgb(255, 179, 0)",
  yellow: "rgb(255, 238, 88)",
  red: "rgb(208, 31, 42)",
  redHover: "rgb(183, 28, 28, 1)",
  orange: "rgb(197, 54, 12)",
  green: "rgb(29, 129, 66)",
  blue: "rgb(2, 111, 177)",
  blueHover: "rgb(1, 87, 155)",
  lightBlue: "rgb(166, 213, 250)",
};

export const theme = createTheme({
  typography: {
    body_regular: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    body_medium: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    title1_regular: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "32px",
      letterSpacing: "0.15px",
    },
    title1_medium: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "32px",
      letterSpacing: "0.15px",
    },
    title3_medium: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0.15px",
    },
    label_regular: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    label_medium: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    subtitle1_medium: {
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    subtitle1_regular: {
      fontSize: "15px",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    subtitle3_regular: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0.25px",
    },
    button_small: {
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "22px",
      letterSpacing: "0.25px",
    },
    references_regular: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
  },
  palette: {
    primary: {
      main: colors.amber,
      dark: colors.amberHover,
      light: colors.yellow,
    },
    error: {
      main: colors.red,
      dark: colors.redHover,
      light: colors.orange,
    },
    success: {
      main: colors.green,
    },
    info: {
      main: colors.blue,
      dark: colors.blueHover,
      light: colors.lightBlue,
    },

    // Custom palettes
    // greys ------------------------------------------------------------------
    grey1: { main: "rgb(0, 0, 0, 0.01)" },
    grey4: { main: "rgb(0, 0, 0, 0.04)" },
    grey8: { main: "rgb(0, 0, 0, 0.08)" },
    grey12: { main: "rgb(0, 0, 0, 0.12)" },
    grey28: { main: "rgb(0, 0, 0, 0.28)" },
    grey38: { main: "rgb(0, 0, 0, 0.38)" },
    grey60: { main: "rgb(0, 0, 0, 0.6)" },
    grey87: { main: "rgb(0, 0, 0, 0.87)" },
    greyBlue: {
      main: "rgb(62, 64, 76)",
    },
    white: {
      main: "rgb(245, 245, 245)",
      light: "rgb(255, 255, 255)",
    },
    whiteish: {
      main: "rgb(242, 245, 249)",
    },
    warningBlue: { main: "rgba(2, 111, 177, 1)" },
    blue8: { main: "rgba(2, 111, 177, 0.08)" },
    blueSolid4: { main: "rgba(245, 249, 252, 1)" },
    blue: {
      main: "rgba(2, 111, 177, 1)",
      hover: "rgba(1, 87, 155, 1)",
      alpha50: "rgba(2, 111, 177, 0.50)",
      alpha38: "rgba(2, 111, 177, 0.38)",
      alpha12: "rgba(2, 111, 177, 0.12)",
      alpha8: "rgba(2, 111, 177, 0.08)",
      alpha4: "rgba(2, 111, 177, 0.04)",
    },
    red: {
      main: "rgb(208, 31, 42, 1)",
      hover: "rgba(183, 28, 28, 1)",
      alpha38: "rgba(208, 31, 42, 0.38)",
      alpha4: "rgb(208, 31, 42, 0.04)",
    },
    amber: {
      main: "rgba(255, 193, 7, 1)",
      hover: "rgba(255, 179, 0, 1)",
      alpha50: "rgba(255, 193, 7, 0.5)",
      alpha38: "rgba(255, 193, 7, 0.38)",
      alpha12: "rgba(255, 193, 7, 0.12)",
      alpha8: "rgba(255, 193, 7, 0.08)",
    },
    purple: { main: "rgba(90, 24, 154, 1)" },
  },
  shape: {
    borderRadius: 2,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          lineHeight: "24px",
          "&.Mui-focusVisible": {
            outline: `2px solid ${theme.palette.blue.main}`,
          },
        }),
        contained: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: ".75rem",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-required::after": {
            content: `" (${gettext("Required")})"`,
            fontWeight: "200",
          },
        },
        asterisk: {
          display: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontWeight: 500,
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        switchBase: {
          "&.Mui-disabled": {
            color: "rgba(224, 224, 224, 1)",
          },
          "&.Mui-disabled.Mui-checked": {
            color: "rgba(224, 224, 224, 1)",
          },
        },
        track: {
          ".Mui-disabled.Mui-disabled + &": {
            opacity: 1.0,
            backgroundColor: "rgba(189, 189, 189, 1)",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: "none",
          color: theme.palette.grey60.main,
          fontSize: 12,
          fontWeight: 400,
          "&:hover": {
            backgroundColor: theme.palette.grey8.main,
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.grey87.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          color: theme.palette.white.main,
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.5,
          maxWidth: "300px",
          padding: "8px 14px",
        }),
      },
    },
    MuiInput: {
      styleOverrides: {
        root: () => ({
          "&:before": {
            borderBottom: "1px solid rgb(224, 224, 224)",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "1px solid rgb(224, 224, 224)",
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:hover": {
            backgroundColor: theme.palette.grey4.main,
          },
        }),
      },
    },
  },
});
