import {
  createAction,
  Action,
  AnyAction,
  PrepareAction,
} from "@reduxjs/toolkit";
import {
  WAIT_FOR_ACTION,
  ERROR_ACTION,
  CALLBACK_ERROR_ARGUMENT,
} from "redux-wait-for-action";

export const createIntentAction = (name: string) =>
  createAction(`${name}_INTENT`, (value = true) => ({
    payload: {
      name,
      value,
    },
  }));

export type ApiActionCreator = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (...args: any[]): Action<string>;
  intent(value?: boolean): Action<string>;
  reset(): Action<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request(data: any): Action<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  success(responseData: any): Action<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(response: any): Action<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  complete(response: any): Action<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submit(...args: any[]): Action<string>;
};

export const createApiAction = <P>(
  type: string,
  prepareAction?: PrepareAction<P>
): ApiActionCreator => {
  const pA = prepareAction || ((payload: P) => ({ payload }));
  const action = createAction(type, pA) as unknown as ApiActionCreator;

  action.intent = createIntentAction(type);
  action.reset = createAction(`${type}_RESET`);
  action.request = createAction(`${type}_REQUEST`);
  action.success = createAction(`${type}_SUCCESS`);
  action.error = createAction(`${type}_ERROR`);
  action.complete = createAction(`${type}_COMPLETE`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action.submit = (...args: any[]) => ({
    ...action(...args),
    [WAIT_FOR_ACTION]: `${type}_SUCCESS`,
    [ERROR_ACTION]: `${type}_ERROR`,
    [CALLBACK_ERROR_ARGUMENT]: (action: AnyAction) => action.payload,
  });

  return action;
};
